<template>
    <div>

        <!-- CLAIM DETAILS -->
        <div class="row mg-b-20">
            <div class="col-12">

                <div class="card">
                    <div class="card-header tx-white">
                        Claim Details - {{$store.state.auth.demo ? 'REFERENCE' : data.reference}} <button v-if="$can('claims-process')" class="btn btn-primary btn-sm float-right mg-l-10" @click="allowProcessing">Accept Current Evidence</button> <button v-if="$can('claims-process')" class="btn btn-danger btn-sm float-right" @click="cancel">Cancel</button>
                    </div>
                    <div class="card-body">

                        <p class="font-weight-bold">Claim Details</p>

                        <table class="table table-striped table-hover tx-white" width="100%">
                            <thead class="thead-colored thead-primary">
                            <tr>
                                <th>Fuel</th>
                                <th>Litres</th>
                                <th>Value</th>
                                <th>Pump</th>
                                <th>User</th>
                                <th>Site</th>
                                <th>Incident Timestamp</th>
                                <th>Status</th>
                                <th>Reference</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{data.fuel}}</td>
                                <td>{{Number.parseFloat(data.litres).toFixed(2)}}</td>
                                <td>£{{Number.parseFloat(data.value).toFixed(2)}}</td>
                                <td>{{data.pump}}</td>
                                <td>{{$store.state.auth.demo ? 'Reported By' : data.user}}</td>
                                <td>{{$store.state.auth.demo ? 'Site' : data.site}}</td>
                                <td>{{created_at}}</td>
                                <td>{{data.status}}</td>
                                <td>{{$store.state.auth.demo ? 'Reference' : data.reference}}</td>
                            </tr>
                            </tbody>
                        </table>

                        <br>

                        <p class="font-weight-bold">Vehicle Information</p>

                        <table class="table table-striped table-hover tx-white" width="100%">
                            <thead class="thead-colored thead-primary">
                            <tr>
                                <th>Plate</th>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Colour</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{data.plate}}</td>
                                <td vars-ref="make">{{data.vehicle_make}}</td>
                                <td vars-ref="model">{{data.vehicle_model}}</td>
                                <td vars-ref="colour">{{data.vehicle_colour}}</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mg-b-10">
            <h4 class="tx-bold tx-uppercase"><span class="tx-danger">important:</span> Please upload at least 1 clear image of the vehicle and 1 clear image of the till receipt for the transaction.</h4>
        </div>

        <!-- UPLOAD EVIDENCE -->
        <div class="row mg-b-20">
            <div class="col-6">

                <div class="card">
                    <div class="card-header tx-white">
                        Vehicle Image Upload
                    </div>
                    <div class="card-body">

                        <p class="tx-bold tx-danger" v-if="data.vehicleCount < 1">You are currently missing a vehicle image!</p>

                        <div class="progress mg-b-20" v-if="images.vehicleUploading">
                            <div class="progress-bar" v-bind:style="{width: images.vehicleProgress.toFixed(0) + '%'}" role="progressbar" v-bind:aria-valuenow="images.vehicleProgress.toFixed(0)" aria-valuemin="0" aria-valuemax="100">{{images.vehicleProgress.toFixed(0)}}%</div>
                        </div>

                        <button class="btn btn-success float-right" v-if="images.vehicle !== null" @click="uploadVehicle">Upload</button>
                        <button class="btn btn-danger float-right mg-r-10" v-if="images.vehicle !== null" @click="clearVehicle">Clear</button>

                        <div class="form-group row mg-b-10">
                            <label class="col-md-3 col-form-label">Vehicle Image</label>
                            <div class="col-md-9">
                                <div class="custom-file">
                                    <input type="file" id="vehicle_image" accept="image/png,image/jpeg" ref="file" @change="selectVehicleFile" class="custom-file-input">
                                    <label class="custom-file-label">{{vehicle}}</label>
                                </div>
                            </div>
                        </div>

                        <img v-if="images.vehicleDataURL !== null" style="width: 100%; height: auto;" :src="images.vehicleDataURL">

                    </div>
                </div>

            </div>
            <div class="col-6">

                <div class="card">
                    <div class="card-header tx-white">
                        Till Receipt Upload
                    </div>
                    <div class="card-body">

                        <p class="tx-bold tx-danger" v-if="data.tillCount < 1">You are currently missing a till receipt!</p>

                        <div class="progress mg-b-20" v-if="images.receiptUploading">
                            <div class="progress-bar" v-bind:style="{width: images.receiptProgress.toFixed(0) + '%'}" role="progressbar" v-bind:aria-valuenow="images.receiptProgress.toFixed(0)" aria-valuemin="0" aria-valuemax="100">{{images.receiptProgress.toFixed(0)}}%</div>
                        </div>

                        <button class="btn btn-success float-right" v-if="images.receipt !== null" @click="uploadReceipt">Upload</button>
                        <button class="btn btn-danger float-right mg-r-10" v-if="images.receipt !== null" @click="clearReceipt">Clear</button>

                        <div class="form-group row mg-b-10">
                            <label class="col-md-3 col-form-label">Till Receipt Image</label>
                            <div class="col-md-9">
                                <div class="custom-file">
                                    <input type="file" id="till_image" accept="image/png,image/jpeg" @change="selectReceiptFile" class="custom-file-input">
                                    <label class="custom-file-label">{{receipt}}</label>
                                </div>
                            </div>
                        </div>

                        <img v-if="images.receiptDataURL !== null" style="width: 100%; height: auto;" :src="images.receiptDataURL">

                    </div>
                </div>

            </div>
        </div>

        <div class="row mg-b-20">
            <div class="col-12">
                <div class="card">
                    <div class="card-header tx-white">
                        Uploaded Evidence
                    </div>
                    <div class="card-body" style="max-height: 600px; overflow-y: scroll;">

<!--                        <loader :show="loading.events"></loader>-->

<!--                        <img v-for="i in data.assets" @click="i.type === 'event' ? showImage(i.id) : showImageTill(i.id)" width="250px" height="auto" :src=" i.type === 'event' ? ('https://api.varsanpr.com/test/admin/events/' + i.id + '/thumbnail?token='+user.socket_token+'&site_id=' + data.site_id) : ('https://api.varsanpr.com/test/admin/claims/tillreceipt/' + i.id + '?token='+user.socket_token+'&site_id=' + data.site_id)" alt="Event Image">-->

                        <secure-image v-for="i in data.assets" :key="i.id" :site_id="data.site_id" :endpoint="'/v2/evidence/'" :filename="i.filename"></secure-image>

<!--                        <div v-for="i in evidenceImages">-->
<!--                            <img v-if="i.type === 'event'" @click="showImage(i.id)" width="250px" height="auto" :src="'https://api.varsanpr.com/test/admin/events/' + i.id + '/thumbnail?token='+user.socket_token+'&site_id=' + data.site_id" alt="Event Image">-->
<!--                            <img v-else @click="showImageTill(i.id)" width="250px" height="auto" :src="'https://api.varsanpr.com/test/admin/claims/tillreceipt/' + i.id + '?token='+user.socket_token+'&site_id=' + data.site_id" alt="Event Image">-->
<!--                        </div>-->

                    </div>
                </div>
            </div>
            <div id="eventImageModal" v-if="popups.event.showing" class="modal fade effect-just-me show" style="display: block; padding-right: 17px;">
                <div class="modal-dialog modal-dialog-centered" style="max-width: 80% !important;" role="document">
                    <div class="modal-content bd-0 tx-14">
                        <div class="modal-body pd-25">
                            <div class="row">
                                <div class="col-12">
                                    <div class="img-magnifier-container">
                                        <img v-show="!popups.event.imageLoading" crossorigin="anonymous" id="event_image_popup" :src="`${popups.event.imageUrl}`" @load="imageLoaded" style="width: 100%; height: auto;">
                                    </div>
                                    <div v-show="popups.event.imageLoading">
                                        <div class="d-flex ht-300 pos-relative align-items-center">
                                            <div class="sk-folding-cube">
                                                <div class="sk-cube1 sk-cube"></div>
                                                <div class="sk-cube2 sk-cube"></div>
                                                <div class="sk-cube4 sk-cube"></div>
                                                <div class="sk-cube3 sk-cube"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <button class="btn btn-danger tx-11 tx-uppercase" @click="closeImage" style="position: absolute; top: 12px; right: 25px; z-index: 10000; font-size: 15px; font-weight: bold;" ><i class="fa fa-times"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- modal-dialog -->
            </div>
            <div id="tillImageMoadl" v-if="popups.till.showing" class="modal fade effect-just-me show" style="display: block; padding-right: 17px;">
                <div class="modal-dialog modal-dialog-centered" style="max-width: 80% !important;" role="document">
                    <div class="modal-content bd-0 tx-14">
                        <div class="modal-body pd-25">
                            <div class="row">
                                <div class="col-12">
                                    <div class="img-magnifier-container">
                                        <img v-show="!popups.till.imageLoading" crossorigin="anonymous" id="till_image_popup" :src="`${popups.till.imageUrl}`" @load="imageLoadedTill" style="width: 100%; height: auto; max-width:100%;">
                                    </div>
                                    <div v-show="popups.till.imageLoading">
                                        <div class="d-flex ht-300 pos-relative align-items-center">
                                            <div class="sk-folding-cube">
                                                <div class="sk-cube1 sk-cube"></div>
                                                <div class="sk-cube2 sk-cube"></div>
                                                <div class="sk-cube4 sk-cube"></div>
                                                <div class="sk-cube3 sk-cube"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <button class="btn btn-danger tx-11 tx-uppercase" @click="closeImageTill" style="position: absolute; top: 12px; right: 25px; z-index: 10000; font-size: 15px; font-weight: bold;" ><i class="fa fa-times"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- modal-dialog -->
            </div>
        </div>

    </div>
</template>

<script>
import Loader from "@/components/Loader";
import SecureImage from "@/components/SecureImage";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "ManageEvidence",
    components: {SecureImage, Loader},
    data(){
        return {
            data: {
                id: -1,
            },
            images: {
                vehicle: null,
                vehicleDataURL: null,
                vehicleProgress: 0,
                vehicleUploading: false,
                receipt: null,
                receiptDataURL: null,
                receiptProgress: 0,
                receiptUploading: false,
            },
            loadingData: false,
            events: [

            ],
            receipts: [

            ],
            evidenceImages: [],
            loading: {
                events: true,
                receipts: true
            },
            popups: {
                event: {
                    showing: false,
                    imageUrl: "",
                    imageLoading: false,
                },
                till: {
                    showing: false,
                    imageUrl: "",
                    imageLoading: false,
                }
            }
        }
    },
    mounted() {
        this.data.id = parseInt(this.$route.params.id || -1);
        this.load();
    },
    methods: {
        load(){
            axios.get(`https://server.varsanpr.com/v2/claims/evidence/${this.data.id}?client_id=${this.$store.state.auth.user.selectedClient}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.data = response.data.claim;
                    this.loadingData = false;
                    this.fetchMMC();
                    // this.fetchUploaded();

                })
                .catch(error => {
                    this.$error("Unable to load claim. Please try again later.", error);
                    this.loadingData = false;
                });
        },
        allowProcessing(){
            axios.patch(`https://server.varsanpr.com/v2/claims/${this.data.id}/pending`, {
                client_id: this.$store.state.auth.user.selectedClient
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.data.status = 'Pending';
                    this.$success("This claim can now be processed!");
                })
                .catch(error => {
                    this.$error("Failed updating claim.", error);
                });
        },
        cancel(){
            let reason = prompt("Why are you cancelling this claim?");
            if(reason.length < 2){
                return this.cancel();
            }
            axios.patch(`https://server.varsanpr.com/v2/claims/${this.data.id}/cancelled`, {
                reason: reason,
                client_id: this.$store.state.auth.user.selectedClient
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.data.status = 'Cancelled';

                })
                .catch(error => {
                    this.$error("Failed cancelling claim.", error);
                });
        },
        selectVehicleFile(event){
            this.images.vehicle = event.target.files[0];
        },
        selectReceiptFile(event){
            this.images.receipt = event.target.files[0];
        },
        clearVehicle(){
            this.images.vehicle = null;
            this.images.vehicleDataURL = null;
        },
        clearReceipt(){
            this.images.receipt = null;
            this.images.receiptDataURL = null;
        },
        uploadVehicle(){
            this.images.vehicleUploading = true;
            if(this.images.vehicle === null){
                this.$error("Please select an image before uploading!");
                this.images.vehicleUploading = false;
                return;
            }
            let data = new FormData();
            data.append('file', this.images.vehicle, this.images.vehicle.name);
            data.append('type', 'vehicle_image');
            data.append('client_id', this.$store.state.auth.user.selectedClient);
            let config = {
                onUploadProgress: (event) => {
                    this.images.vehicleProgress = Math.round((event.loaded * 100) / event.total);
                },
                headers: authHeader()
            }
            axios.put(`https://server.varsanpr.com/v2/claims/evidence/${this.data.id}`, data, config)
            .then((response) => {
                this.$success("Successfully uploaded vehicle image.");
                this.images.vehicleUploading = false;
                this.images.vehicleProgress = 0;
                this.images.vehicle = null;
                this.images.vehicleDataURL = null;
                this.load();
            })
            .catch((error) => {
                this.$error("Failed to upload vehicle image", error);
                this.images.vehicleUploading = false;
                this.images.vehicleProgress = 0;
            });
        },
        uploadReceipt(){
            this.images.receiptUploading = true;
            if(this.images.receipt === null){
                this.$error("Please select an image before uploading!");
                this.images.receiptUploading = false;
                return;
            }
            let data = new FormData();
            data.append('file', this.images.receipt, this.images.receipt.name);
            data.append('type', 'till_receipt');
            data.append('client_id', this.$store.state.auth.user.selectedClient);
            let config = {
                onUploadProgress: (event) => {
                    this.images.receiptProgress = Math.round((event.loaded * 100) / event.total);
                },
                headers: authHeader()
            }
            axios.put(`https://server.varsanpr.com/v2/claims/evidence/${this.data.id}`, data, config)
                .then((response) => {
                    this.$success("Successfully uploaded receipt image.");
                    this.images.receiptUploading = false;
                    this.images.receiptProgress = 0;
                    this.images.receipt = null;
                    this.images.receiptDataURL = null;
                    this.load();
                })
                .catch((error) => {
                    this.$error("Failed to upload receipt image", error);
                    this.images.receiptUploading = false;
                    this.images.receiptProgress = 0;
                });
        },
        imageLoaded(){
            this.popups.event.imageLoading = false;
            this.magnify('event_image_popup', 3);
        },
        imageLoadedTill(){
            this.popups.till.imageLoading = false;
            this.magnify('till_image_popup', 3);
        },
        closeImage(){
            this.popups.event.showing = false;
            this.popups.event.imageUrl = "";
            this.popups.event.imageLoading = false;
        },
        showImage(id){
            this.popups.event.imageUrl = 'https://api.varsanpr.com/test/admin/events/' + id + '/image?token='+this.user.socket_token+'&site_id=' + this.data.site_id;
            this.popups.event.showing = true;
            this.popups.event.imageLoading = true;
        },
        closeImageTill(){
            this.popups.till.showing = false;
            this.popups.till.imageUrl = "";
            this.popups.till.imageLoading = false;
        },
        showImageTill(id){
            this.popups.till.imageUrl = 'https://api.varsanpr.com/test/admin/claims/tillreceipt/' + id + '?token='+this.user.socket_token+'&site_id=' + this.data.site_id;
            this.popups.till.showing = true;
            this.popups.till.imageLoading = true;
        },
        magnify(imgID, zoom) {
            //https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp
            var img, glass, w, h, bw;
            img = document.getElementById(imgID);
            /*create magnifier glass:*/
            glass = document.createElement("DIV");
            glass.setAttribute("class", "img-magnifier-glass");
            /*insert magnifier glass:*/
            img.parentElement.insertBefore(glass, img);
            /*set background properties for the magnifier glass:*/
            glass.style.backgroundImage = "url('" + img.src + "')";
            glass.style.backgroundRepeat = "no-repeat";
            glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
            bw = 3;
            w = glass.offsetWidth / 2;
            h = glass.offsetHeight / 2;
            /*execute a function when someone moves the magnifier glass over the image:*/
            glass.addEventListener("mousemove", moveMagnifier);
            img.addEventListener("mousemove", moveMagnifier);
            /*and also for touch screens:*/
            glass.addEventListener("touchmove", moveMagnifier);
            img.addEventListener("touchmove", moveMagnifier);
            function moveMagnifier(e) {
                var pos, x, y;
                /*prevent any other actions that may occur when moving over the image*/
                e.preventDefault();
                /*get the cursor's x and y positions:*/
                pos = getCursorPos(e);
                x = pos.x;
                y = pos.y;
                glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
                /*prevent the magnifier glass from being positioned outside the image:*/
                if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
                if (x < w / zoom) {x = w / zoom;}
                if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
                if (y < h / zoom) {y = h / zoom;}
                /*set the position of the magnifier glass:*/
                glass.style.left = (x - w) + "px";
                glass.style.top = (y - h) + "px";
                /*display what the magnifier glass "sees":*/
                glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
            }
            function getCursorPos(e) {
                var a, x = 0, y = 0;
                e = e || window.event;
                /*get the x and y positions of the image:*/
                a = img.getBoundingClientRect();
                /*calculate the cursor's x and y coordinates, relative to the image:*/
                x = e.pageX - a.left;
                y = e.pageY - a.top;
                /*consider any page scrolling:*/
                x = x - window.pageXOffset;
                y = y - window.pageYOffset;
                return {x : x, y : y};
            }
        },
        fetchMMC(){
            axios.get(`https://server.varsanpr.com/v2/external/dvsa/${this.data.plate}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.data.vehicle_make = response.data.vehicle.make;
                    this.data.vehicle_model = response.data.vehicle.model;
                    this.data.vehicle_colour = response.data.vehicle.colour;
                })
                .catch(error => {
                    // this.$error("Unable to find the Make, Model and Colour for this vehicle.", error);
                });
        },
    },
    computed: {
        created_at: function(){
            if(this.data.incident_timestamp === undefined) return "";
            return moment(this.data.incident_timestamp, "YYYY-MM-DD HH:mm:ss.SSS").format("DD/MM/YYYY HH:mm:ss");
        },
        vehicle: function(){
            if(this.images.vehicle == null){
                return "Select File";
            }
            if(this.images.vehicle == null){
                return "File Selected";
            }
            let reader = new FileReader();
            reader.onload = (e) => {
                this.images.vehicleDataURL = e.target.result;
            };
            reader.readAsDataURL(this.images.vehicle);
            return this.images.vehicle.name;
        },
        receipt: function(){
            if(this.images.receipt == null){
                return "Select File";
            }
            if(this.images.receipt == null){
                return "File Selected";
            }
            let reader = new FileReader();
            reader.onload = (e) => {
                this.images.receiptDataURL = e.target.result;
            };
            reader.readAsDataURL(this.images.receipt);
            return this.images.receipt.name;
        },
    }
}
</script>

<style scoped>

</style>